function getDomain(hasDashboard = 'yes'){
    let domainName =''
    let hostName = window.location.hostname
    if( hostName == 'localhost' ){
        domainName = 'https://development.nualim.com/api/dashboard/'
    }else if( hostName == 'devdash.nualim.com' ){
        domainName = 'https://development.nualim.com/api/dashboard/'
    }
    else if( hostName == 'testdash.nualim.com' ){
        domainName = 'https://testing.nualim.com/api/dashboard/'
    }
    else if( hostName == 'dash.nualim.com' || hostName == 'dashboard.nualim.com' ){
        domainName = 'https://api.nualim.com/api/dashboard/'
    }
    else{
        domainName = 'https://development.nualim.com/api/dashboard/'
    }
    if(hasDashboard == 'no' ){
        domainName = domainName.slice(0 , -10);
    }
    
    
    
    return domainName
}

export default getDomain
